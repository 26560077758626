import { createSlice } from "@reduxjs/toolkit";

export const selfOrderMainSlice=createSlice({
    name:"selfOrderMainSlice",
    initialState:{
        screenSelected:null,
        loginType:null,
        selfDialogScreen:null
    },
    reducers:{
      setScreenSelected:(state,action)=>{
        state.screenSelected=action.payload;
      },
      setScreenLoginType:(state,action)=>{
        state.loginType=action.payload;
      },
      selfDialogScreenSelected:(state,action)=>{
        state.selfDialogScreen=action.payload;
      }
    }
})

export const {
    setScreenSelected,
    setScreenLoginType,
    selfDialogScreenSelected
}=selfOrderMainSlice.actions

export default selfOrderMainSlice.reducer