import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFloorDetailsForBilling } from '../../../component/Modules/Sales/Billing/API/billingFloorSection';
import { Rnd } from 'react-rnd';
import { shapeTypes, structures } from '../../../component/Modules/FloorManagement/utils/floorManagementEnum';
import LineSegmentTool from '../../../component/Modules/FloorManagement/components/icons/LineSegmentTool';
import StarTool from '../../../component/Modules/FloorManagement/components/icons/StarTool';
import Chair from '../../../component/Modules/FloorManagement/components/icons/Chair';
import { Tab, Tabs } from '@mui/material';
import { rgba } from 'polished';
import SelfOrderFooter from '../Navbar/SelfOrderFooter';
import { setSelfOrderType } from './selfOrderEssentialsSlice';
import { errorSnackMsg } from '../../../component/Custom Hooks/SnackkBarUtilities';

const SelfOrderFloorPlan = () => {
    const dispatch=useDispatch()

    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const defaultFloorId = localStorage.getItem("defaultFloorId");

    const { selectedFloorDetails } = useSelector((store) => store.billingFloorPlanSlice);
    const { branchConfigurationList } = useSelector((state) => state.newBillingSlice);
    const { floors } = useSelector((state) => state.addSeatSlice);

    const [floorSelected, setFloorSelected] = useState(null)
    const [orderIdColorMapping, setOrderIdColorMapping] = useState({});
    const [selectedTables, setSelectedTables] = useState([])
    const [selectedChairs, setSelectedChairs] = useState([])

    const getSelectedTabColor = (originalColor) => {
        try {
            return rgba(originalColor, 0.5);
        } catch (error) {
            return rgba("#f67878", 0.5);
        }
    };

    const getRandomColor = () => {
        const generateRandomValue = () => Math.floor(Math.random() * 200);
        const color = `rgb(${generateRandomValue()}, ${generateRandomValue()}, ${generateRandomValue()})`;

        return color;
    };

    const getFirstObject = (list) => {
        if (list.length !== 0) {
            return list[0]?._id
        } else {
            return null
        }
    }
    // Set floor tab details
    const handleTabDetails = (res) => {

        getFloorDetailsForBilling({ id: res._id, branchId: userInfo?.branchPk });
        localStorage.setItem("defaultFloorId", res._id)
        setSelectedTables([]);
        setSelectedChairs([])
    };
    const handleShapeSelected = (shape)  => {
        if(shape.isUsed){
            errorSnackMsg("Seat already in use !!")
        }
        else{
        if (shape.type === "CHAIR") {
            let seatSelection=selectedFloorDetails?.seatSelection
            if(seatSelection){
                const chairSelected = selectedChairs.some((obj) => obj === shape?._id);
                if (chairSelected) {
                    const updatedSelectedObjects = selectedChairs.filter((obj) => obj !== shape._id);
                    setSelectedChairs(updatedSelectedObjects);
                } else {
                    setSelectedChairs([...selectedChairs, shape._id]);
                    setSelectedTables([...selectedTables, shape.tableId]);
                }
            }else{
                errorSnackMsg("Chair selection disabled for this floor")
            }
        }
        else if(shape.type==="TABLE"){
            const tableSelected = selectedTables.some((obj) => obj === shape._id);
            if (tableSelected) {
                const updatedSelectedObjects = selectedTables.filter((obj) => obj !== shape._id);
                const filteredChairs = selectedChairs.filter(chairId => {
                    const matchingObject = selectedFloorDetails?.floorCanvas.find(obj => obj._id === chairId);
                    return !matchingObject || matchingObject.tableId !== shape._id;
                  });

                setSelectedTables(updatedSelectedObjects);
                setSelectedChairs(filteredChairs)
            } else {
                setSelectedTables([...selectedTables, shape._id]);
                const filteredIds = selectedFloorDetails?.floorCanvas
                    .filter((obj) => obj.tableId === shape._id)
                    .map((obj) => obj._id);
                const uniqueIds = new Set(selectedChairs.concat(filteredIds));
                uniqueIds.size !== 0 && setSelectedChairs([...uniqueIds]);
            }
        }
        }
    }  
    const backBtnFunction=()=>{
        dispatch(setSelfOrderType(null))
    }
    const nextBtnFn=()=>{
        dispatch(setSelfOrderType("orderProductLists"))
    }

    useEffect(() => {
        const index = floors.findIndex(obj => obj._id === defaultFloorId);

        if (floors?.length !== 0) {
            let inFloor = index >= 0 ? index : false;
            setFloorSelected(inFloor || 0)
            getFloorDetailsForBilling({ id: floors[inFloor || 0]?._id, branchId: userInfo?.branchPk });
        }
    }, [floors])

    return (
        <div className='self-order-floor-parent'>
            <h3>SELECT A TABLE</h3>
            <Tabs
                value={floorSelected}
                onChange={(e, newValue) => {
                    setFloorSelected(newValue)
                }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className="floor-container"
                sx={{
                    "& .MuiTabs-indicator": {
                        backgroundColor: `${"red"} !important`,
                    },
                }}
            >
                {floors?.map((res, i) => (
                    <Tab
                        key={i}
                        sx={{
                            backgroundColor: getSelectedTabColor(res?.color) || rgba("#f67878", 1),
                            minWidth: floors?.length,
                            color: "white",
                            "&.Mui-selected": {
                                backgroundColor: res?.color || "#f67878",
                                color: "white",
                            },
                            minWidth: 250
                        }}
                        className="floor-box"
                        label={res?.nameOfFloor}
                        onClick={() => handleTabDetails(res)}
                    />
                ))}
            </Tabs>
            {

                (floorSelected === null) ? <div className="center-div">Select a floor</div> :
                    <>
                        {
                            selectedFloorDetails?.floorCanvas.length === 0 ? <div className="center-div">No Floor plan added</div> :
                                <div className="tab-panel-container">
                                    <div
                                        className="sub-tab-wrapper"
                                    >
                                        {selectedFloorDetails?.floorCanvas.map((shape) => (
                                            <Rnd
                                                key={shape._id}
                                                size={{
                                                    width: shape.size.width,
                                                    height: shape.size.height,
                                                }}
                                                position={{
                                                    x: shape.position.x,
                                                    y: shape.position.y,
                                                }}
                                                minWidth={10}
                                                minHeight={10}
                                                bounds="parent"
                                                // onTouchStart={()=>handleShapeSelected(shape)}
                                                onClick={() =>handleShapeSelected(shape)}
                                                enableResizing={false}
                                                disableDragging={true}
                                            >
                                                {

                                                    shape.type === shapeTypes.SHAPE ? (
                                                        <div
                                                            className="shape-container"
                                                            style={{ border: "transparent" }}
                                                        >
                                                            {shape?.structure === structures.LINESEGMENTTOOL ? (
                                                                <LineSegmentTool
                                                                    color={
                                                                        // selectedElementDetails?._id === shape._id
                                                                        // ? "#e91d1d"
                                                                        // :
                                                                        shape?.color
                                                                    }
                                                                    width={shape?.size?.width}
                                                                    height={shape?.size?.height}
                                                                />
                                                            ) : shape?.structure === structures.SPLITFLOOR ? (
                                                                <div
                                                                    className="shape-container"
                                                                    style={{
                                                                        backgroundColor:
                                                                            // selectedElementDetails?._id === shape._id
                                                                            //   ? "#e91d1d"
                                                                            //   : 
                                                                            shape?.color,
                                                                    }}
                                                                />
                                                            ) : shape?.structure === structures.HORIZONTAL ? (
                                                                <div
                                                                    className="shape-container"
                                                                    style={{
                                                                        backgroundColor: shape?.color,
                                                                    }}
                                                                />
                                                            ) : shape?.structure === structures.STAR ? (
                                                                <StarTool
                                                                    color={
                                                                        // selectedElementDetails?._id === shape._id
                                                                        //   ? "#e91d1d"
                                                                        //   :
                                                                        shape?.color
                                                                    }
                                                                    width={shape?.size?.width}
                                                                    height={shape?.size?.height}
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    ) : shape.type === shapeTypes.TABLE ? (
                                                        <>
                                                            {
                                                                <div
                                                                    className={`shape-container ${shape.structure}`}
                                                                    style={{
                                                                        backgroundColor:
                                                                            (getFirstObject(shape?.orders) && !(branchConfigurationList?.enableMultipleOrder)) ? (
                                                                                orderIdColorMapping[getFirstObject(shape?.orders)] || (setOrderIdColorMapping((prevMapping) => ({ ...prevMapping, [getFirstObject(shape?.orders)]: getRandomColor() })), orderIdColorMapping[getFirstObject(shape?.orders)])
                                                                            ) :
                                                                                (selectedTables.includes(shape._id) ? 'red' : '#80808082'),
                                                                        cursor: "pointer"
                                                                    }}
                                                                >
                                                                    <span style={{userSelect:"none"}}>{shape.name}</span>
                                                                </div>

                                                            }
                                                        </>
                                                    ) : (
                                                        <div
                                                            className="chair-container"
                                                            // onClick={() => handleShapeSelected(shape)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <Chair
                                                                color={
                                                                    ((getFirstObject(shape?.orders) && !(branchConfigurationList?.enableMultipleOrder)) ? (
                                                                        orderIdColorMapping[getFirstObject(shape?.orders)] || (setOrderIdColorMapping((prevMapping) => ({ ...prevMapping, [getFirstObject(shape?.orders)]: getRandomColor() })), orderIdColorMapping[getFirstObject(shape?.orders)])
                                                                    ) :
                                                                        (selectedChairs.includes(shape._id) ? 'red' : '#80808082'))
                                                                }
                                                                width={shape?.size?.width}
                                                                name={shape?.name}
                                                                height={shape?.size?.height}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </Rnd>
                                        ))}
                                    </div>
                                </div>}
                    </>
            }

            <SelfOrderFooter blackBtnText={"BACK"} redBtnText={"NEXT"} blackBtnOnclick={backBtnFunction} redBtnOnClick={nextBtnFn}/>
        </div>
    );
};

export default SelfOrderFloorPlan;
