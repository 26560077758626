import React from 'react'
import "../Input/selfOrderInputcss.css"
import SelfOrderInput from '../Input/SelfOrderInput'
import SelfLoginBtn from '../Buttons/SelfLoginBtn'
import { useDispatch } from 'react-redux'
import { setScreenSelected } from '../../Slices/selfOrderMainSlice'
const SelfLoginPage = () => {
  const dispatch = useDispatch()
  return (
    <div className='self-login-ctn'>
      <div className="self-login-input-ctn">
        <h3>CUSTOMER</h3>

        <div className="self-login-body">

          <SelfOrderInput
            // value={"inputValue"}
            // onChange={handleInputChange}
            placeholder="Enter mobile number"
          />
          <SelfLoginBtn
            label='Continue'
            onClick={() => dispatch(setScreenSelected("registerScreen"))}
          />
        </div>

        <p className="skip" onClick={()=>dispatch(setScreenSelected("orderScreen"))}>SKIP</p>
      </div>

    </div>
  )
}

export default SelfLoginPage