import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint  from "react-to-print";
import ShiftReportTable from "./PosShiftReportTables/ShiftReportTable";
import { viewAllAllowedBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { viewShiftAPICall } from "../../../../API/Settings/Shift/viewShiftAPI";

import ExcelJS from "exceljs"

import { set_shift_report_branch, set_shift_report_shift, set_shift_report_submit } from "./ReportSlice";


function PosNewShiftReport() {
  const dispatch = useDispatch();
  const {shiftReportBranch,shiftReportShift}=useSelector((state)=>state.posReportshiftSlice)

  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const allShiftList = useSelector((state) => state.viewShiftSlice.value);
  const {
    shiftReportDetails,
    billTypeSummary,
    deliveryPartnerSummary,
    deliveryBoysSummary,
    discountSummary,
    categorySalesSummary,
  } = useSelector((state) => state.posReportshiftSlice);
  const [validationAlert, setValidationAlert] = useState({});
  const decimalPosition = localStorage.getItem("decimalPosition");
  const deliveryTotal = (billTypeSummary?.selfDelivery?.total || 0) + (billTypeSummary?.aggregator?.total|| 0)

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Shift Summary Report', { properties: { defaultRowHeight: 20 } });

    // Title and Branch Information
    const titleRow = sheet.addRow(['Advanced Shift Report']);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells('A1:L1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

    const styles = {
        mainHead: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFedf2f9' } },
        subHead: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F8F8' } },
        tableHead: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC0CFF8' } },
        tableSub: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC2C2C2' } },
    };

    // Branch name row
    const branchRow = sheet.addRow([shiftReportDetails?.branchName || '-NIL-']);
    branchRow.font = { size: 15, bold: true };
    sheet.mergeCells(`A${sheet.lastRow._number}:L${sheet.lastRow._number}`);
    branchRow.eachCell(cell => cell.fill = styles.subHead);

    // Shift details
    const shiftDataRows = [
        [`Shift: ${shiftReportDetails?.shiftId || ''}`, `Start Date: ${shiftReportDetails?.startDate || ''}`],
        [`Employee: ${shiftReportDetails?.employeeName || ''}`, `End Date: ${shiftReportDetails?.endDate || 'Active Shift'}`]
    ];

    shiftDataRows.forEach(([text1, text2]) => {
        const row = sheet.addRow([text1, '', '', text2]);
        sheet.mergeCells(`A${row._number}:C${row._number}`);
        sheet.mergeCells(`D${row._number}:L${row._number}`);
        row.eachCell(cell => cell.fill = styles.subHead);
    });

    // Data for Bill Type Summary         
    sheet.addRow(['']); // Blank row
    const billTypeRow = sheet.addRow(['Bill Type', '', '', '', '', '', 'Amount']);
    billTypeRow.font = { size: 14, bold: true };
    for (let i = 1; i <= 7; i++) {
        billTypeRow.getCell(i).fill = styles.mainHead;
    }
    sheet.mergeCells(`A${billTypeRow._number}:D${billTypeRow._number}`);
    sheet.mergeCells(`G${billTypeRow._number}:L${billTypeRow._number}`);
    sheet.getCell(`G${billTypeRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };
    // Set column widths
    sheet.getColumn(1).width = 20; 
    sheet.getColumn(2).width = 20; 
    sheet.getColumn(3).width = 20; 
    sheet.getColumn(4).width = 20; 
    sheet.getColumn(5).width = 20; 
    sheet.getColumn(6).width = 20; 
    // Dine In Section
    const dineInRow = sheet.addRow(['Dine In', '', '', '', '', '', billTypeSummary?.dineIn?.total?.toFixed(decimalPosition) || '0.0']);
    dineInRow.font = { size: 12, bold: true };
    dineInRow.eachCell((cell) => cell.fill = styles.subHead);
    sheet.mergeCells(`A${dineInRow._number}:D${dineInRow._number}`);
    sheet.mergeCells(`G${dineInRow._number}:L${dineInRow._number}`);
    sheet.getCell(`G${dineInRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };
   
    const tableHeaderRow = sheet.addRow(['Bill No', '', '', '', 'Date', '', '', '', 'Amount', '', '', '']);
    tableHeaderRow.font = { bold: true };

// Merge cells for Bill No, Date, and Amount columns
  sheet.mergeCells(`A${tableHeaderRow._number}:D${tableHeaderRow._number}`); // Merge A8 to D8 for Bill No
  sheet.mergeCells(`E${tableHeaderRow._number}:H${tableHeaderRow._number}`); // Merge E8 to H8 for Date
  sheet.mergeCells(`I${tableHeaderRow._number}:L${tableHeaderRow._number}`); // Merge I8 to L8 for Amount

// Set alignment to center for the merged cells
  sheet.getCell(`A${tableHeaderRow._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; // Bill No
  sheet.getCell(`E${tableHeaderRow._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; // Date
  sheet.getCell(`I${tableHeaderRow._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; // Amount
 
// Apply styles (if any) for the table header
tableHeaderRow.eachCell((cell) => {
    cell.fill = styles.tableHead;
});
// If dine-in data exists, append rows for individual bills starting from A9 onwards
billTypeSummary?.dineIn?.list?.forEach((item, index) => {
  const dataRow = sheet.addRow([item.billNo, '', '', '', item.date, '', '', '', item.amount.toFixed(decimalPosition), '', '', '']);

  const rowNumber = dataRow._number;

  // Merge cells for Bill No, Date, and Amount columns in the data rows
  sheet.mergeCells(`A${rowNumber}:D${rowNumber}`); 
  sheet.mergeCells(`E${rowNumber}:H${rowNumber}`); 
  sheet.mergeCells(`I${rowNumber}:L${rowNumber}`); 

  // Set alignment to center for the merged cells
  sheet.getCell(`A${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`E${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`I${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };

});

    // Take Away Section
    const takeAwayRow = sheet.addRow(['Take Away', '', '', '', '', '', billTypeSummary?.takeAway?.total?.toFixed(decimalPosition) || '0.0']);
    takeAwayRow.font = { size: 12, bold: true };
    takeAwayRow.eachCell((cell) => cell.fill = styles.subHead);
    sheet.mergeCells(`A${takeAwayRow._number}:D${takeAwayRow._number}`);
    sheet.mergeCells(`G${takeAwayRow._number}:L${takeAwayRow._number}`);
    sheet.getCell(`G${takeAwayRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };

    // Table headings for Take Away section
    const takeAwayHeaderRow = sheet.addRow(['Bill No', '', '', '', 'Date', '', '', '', 'Amount', '', '', '']);
    takeAwayHeaderRow.font = { bold: true };
    const takeRowNumber=takeAwayHeaderRow._number
    // Merge cells for Bill No, Date, and Amount columns
    sheet.mergeCells(`A${takeRowNumber}:D${takeRowNumber}`); 
    sheet.mergeCells(`E${takeRowNumber}:H${takeRowNumber}`); 
    sheet.mergeCells(`I${takeRowNumber}:L${takeRowNumber}`); 
    
// Set alignment to center for the merged cells
   sheet.getCell(`A${takeRowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
   sheet.getCell(`E${takeRowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
   sheet.getCell(`I${takeRowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
   
    takeAwayHeaderRow.eachCell((cell) => cell.fill = styles.tableHead);

    billTypeSummary?.takeAway?.list?.forEach((item) => {
      const dataRow =  sheet.addRow([item.billNo,"","","", item.date,"","","", item.amount.toFixed(decimalPosition),"","",""]);
      const rowNumber = dataRow._number;

      sheet.mergeCells(`A${rowNumber}:D${rowNumber}`); 
      sheet.mergeCells(`E${rowNumber}:H${rowNumber}`); 
      sheet.mergeCells(`I${rowNumber}:L${rowNumber}`); 

      sheet.getCell(`A${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
      sheet.getCell(`E${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
      sheet.getCell(`I${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // Delivery row
    const deliveryRow = sheet.addRow(['Delivery', '', '', '', '', '', deliveryTotal.toFixed(decimalPosition)]);
    deliveryRow.font = { size: 12, bold: true };
    deliveryRow.eachCell((cell) => cell.fill = styles.subHead);
    sheet.mergeCells(`A${deliveryRow._number}:D${deliveryRow._number}`);
    sheet.mergeCells(`G${deliveryRow._number}:L${deliveryRow._number}`);
    sheet.getCell(`G${deliveryRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };

    // Self Delivery Section
    const selfDeliveryRow = sheet.addRow(['Self Delivery']);
    selfDeliveryRow.font = { size: 12, bold: true };
    selfDeliveryRow.eachCell((cell) => cell.fill = styles.tableSub);
    sheet.mergeCells(`A${selfDeliveryRow._number}:L${selfDeliveryRow._number}`);

      // Table headings for Self delivery section
      const selfDeliveryHeaderRow = sheet.addRow(['Bill No','','','', 'Date','','','', 'Amount','','','',]);
      selfDeliveryHeaderRow.font = { bold: true };
      const selfHeadRow=selfDeliveryHeaderRow._number
      sheet.mergeCells(`A${selfHeadRow}:D${selfHeadRow}`); 
      sheet.mergeCells(`E${selfHeadRow}:H${selfHeadRow}`); 
      sheet.mergeCells(`I${selfHeadRow}:L${selfHeadRow}`); 
    
    // Set alignment to center for the merged cells
      sheet.getCell(`A${selfHeadRow}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
      sheet.getCell(`E${selfHeadRow}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
      sheet.getCell(`I${selfHeadRow}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
      selfDeliveryHeaderRow.eachCell((cell) => cell.fill = styles.tableHead);
  
      billTypeSummary?.selfDelivery?.list?.forEach((item) => {
        const dataRow=sheet.addRow([item.billNo,"","","", item.date,"","","", item.amount.toFixed(decimalPosition),"","","",]);
        const rowNumber = dataRow._number;
     sheet.mergeCells(`A${rowNumber}:D${rowNumber}`); 
     sheet.mergeCells(`E${rowNumber}:H${rowNumber}`); 
     sheet.mergeCells(`I${rowNumber}:L${rowNumber}`); 
   
  // // Set alignment to center for the merged cells
     sheet.getCell(`A${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
     sheet.getCell(`E${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
     sheet.getCell(`I${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
      });

       // Aggregator Section
    const aggregatorRow = sheet.addRow(['Aggregator']);
    aggregatorRow.font = { size: 12, bold: true };
    aggregatorRow.eachCell((cell) => cell.fill = styles.tableSub);
    sheet.mergeCells(`A${aggregatorRow._number}:L${aggregatorRow._number}`);

    // Aggregator Delivery details
    const deliveryHeaderRow = sheet.addRow(['Aggregator', 'Order ID', 'Date', 'Discount', 'Commission', 'Amount']);
    deliveryHeaderRow.font = { bold: true };
    deliveryHeaderRow.eachCell((cell) => cell.fill = styles.tableHead);

    billTypeSummary?.aggregator?.list?.forEach((item) => {
        sheet.addRow([item.aggregatorName, item.billNo, item.date, item.discount, item.commission, item.amount]);
    });

    // Staff Expense Section
    const staffExpenseRow = sheet.addRow(['Staff Expense', '', '', '', '', '', billTypeSummary?.staffExpense?.total?.toFixed(decimalPosition) || '0.0']);
    staffExpenseRow.font = { size: 12, bold: true };
    staffExpenseRow.eachCell((cell) => cell.fill = styles.subHead);
    sheet.mergeCells(`A${staffExpenseRow._number}:D${staffExpenseRow._number}`);
    sheet.mergeCells(`G${staffExpenseRow._number}:L${staffExpenseRow._number}`);
    sheet.getCell(`G${staffExpenseRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };

    const staffExpenseHeaderRow = sheet.addRow(['Expense', 'Employee', 'Date', 'Amount']);
    staffExpenseHeaderRow.font = { bold: true };
    staffExpenseHeaderRow.eachCell((cell) => cell.fill = styles.tableHead);

    billTypeSummary?.list?.forEach((item) => {
        sheet.addRow([item.expense, item.employee, item.date, item.amount.toFixed(decimalPosition)]);
    });
    // Outlet Expense Section
    const outletExpenseRow = sheet.addRow(['Outlet Expense', '', '', '', '', '', billTypeSummary?.outletExpense?.total?.toFixed(decimalPosition) || '0.0']);
    outletExpenseRow.font = { size: 12, bold: true };
    outletExpenseRow.eachCell((cell) => cell.fill = styles.subHead);
    sheet.mergeCells(`A${outletExpenseRow._number}:D${outletExpenseRow._number}`);
    sheet.mergeCells(`G${outletExpenseRow._number}:L${outletExpenseRow._number}`);
    sheet.getCell(`G${outletExpenseRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };

    const outletExpenseHeaderRow = sheet.addRow(['Shift ID', 'Doc No', 'Expense', 'Date', 'Amount']);
    outletExpenseHeaderRow.font = { bold: true };
    outletExpenseHeaderRow.eachCell((cell) => cell.fill = styles.tableHead);

    billTypeSummary?.outletExpense?.list?.forEach((item) => {
        sheet.addRow([item.shift, item.docNo, item.expense, item.date, item.amount.toFixed(decimalPosition)]);
    });

    // Refund Section
    const refundRow = sheet.addRow(['Refund', '', '', '', '', '', billTypeSummary?.refund?.total?.toFixed(decimalPosition) || '0.0']);
    refundRow.font = { size: 12, bold: true };
    refundRow.eachCell((cell) => cell.fill = styles.subHead);
    sheet.mergeCells(`A${refundRow._number}:D${refundRow._number}`);
    sheet.mergeCells(`G${refundRow._number}:L${refundRow._number}`);
    sheet.getCell(`G${refundRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };

    const refundHeaderRow = sheet.addRow(['Order ID', 'Product', 'Type', 'Date', 'Amount']);
    refundHeaderRow.font = { bold: true };
    refundHeaderRow.eachCell((cell) => cell.fill = styles.tableHead);

    billTypeSummary?.refund?.list?.forEach((item) => {
        sheet.addRow([item.billNo, item.product, item.type, item.date, item.amount.toFixed(decimalPosition)]);
    });

    // Cash And Card Section
    const cashdRow = sheet.addRow(['Cash & Card', '', '', '', '', '', billTypeSummary?.cashAndCard?.total?.toFixed(decimalPosition) || '0.0']);
    cashdRow.font = { size: 12, bold: true };
    cashdRow.eachCell((cell) => cell.fill = styles.subHead);
    sheet.mergeCells(`A${cashdRow._number}:D${cashdRow._number}`);
    sheet.mergeCells(`G${cashdRow._number}:L${cashdRow._number}`);
    sheet.getCell(`G${cashdRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };

    const cashHeaderRow = sheet.addRow(['Payment Method', 'Amount', 'Commission', 'Balance']);
    cashHeaderRow.font = { bold: true };
    cashHeaderRow.eachCell((cell) => cell.fill = styles.tableHead);

    billTypeSummary?.cashAndCard?.list?.forEach((item) => {
        sheet.addRow([item.type, item.amount, item.commission, item.balance.toFixed(decimalPosition)]);
    });

    sheet.addRow(['']); 
    const delivryPartnerRow = sheet.addRow(['Delivery Partners', '', '', '', '', '', 'Amount']);
    delivryPartnerRow.font = { size: 14, bold: true };
    for (let i = 1; i <= 7; i++) {
      delivryPartnerRow.getCell(i).fill = styles.mainHead;
    }
    sheet.mergeCells(`A${delivryPartnerRow._number}:D${delivryPartnerRow._number}`);
    sheet.mergeCells(`G${delivryPartnerRow._number}:L${delivryPartnerRow._number}`);
    sheet.getCell(`G${delivryPartnerRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };

    deliveryPartnerSummary?.forEach((partner, index) => {
      const partnerRow = sheet.addRow([partner?.partner, '', '', '', '', '', partner?.total?.toFixed(decimalPosition) || '0.0']);
      partnerRow.font = { size: 12, bold: true };
      sheet.mergeCells(`A${partnerRow._number}:D${partnerRow._number}`);
      sheet.mergeCells(`G${partnerRow._number}:L${partnerRow._number}`);
      sheet.getCell(`G${partnerRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };
      partnerRow.eachCell((cell) => cell.fill = styles.subHead);

      const tableHeaderRow = sheet.addRow(['Order No', '', '', '', 'Date', '', '', '', 'Amount', '', '', '']);
      tableHeaderRow.font = { bold: true, bold: true };
      sheet.mergeCells(`A${tableHeaderRow._number}:D${tableHeaderRow._number}`); // Merge A8 to D8 for Bill No
      sheet.mergeCells(`E${tableHeaderRow._number}:H${tableHeaderRow._number}`); // Merge E8 to H8 for Date
      sheet.mergeCells(`I${tableHeaderRow._number}:L${tableHeaderRow._number}`); // Merge I8 to L8 for Amount

// Set alignment to center for the merged cells
      sheet.getCell(`A${tableHeaderRow._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; // Bill No
      sheet.getCell(`E${tableHeaderRow._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; // Date
      sheet.getCell(`I${tableHeaderRow._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; // Amount
 
// Apply styles (if any) for the table header
    tableHeaderRow.eachCell((cell) => {
        cell.fill = styles.tableHead;
    });
      // If subheaders are shown, display the table with individual orders
      partner?.data?.forEach((order) => {
        
          const orderRow = sheet.addRow([order.orderNo, '', '', '', order.date, '', '', '', order.amount?.toFixed(decimalPosition) || '0.0']);
          const rowNumber = orderRow._number;

          sheet.mergeCells(`A${rowNumber}:D${rowNumber}`);
          sheet.mergeCells(`E${rowNumber}:H${rowNumber}`);
          sheet.mergeCells(`I${rowNumber}:L${rowNumber}`);

          sheet.getCell(`A${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
          sheet.getCell(`E${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
          sheet.getCell(`I${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };

          // orderRow.eachCell((cell) => cell.fill = styles.subHead);
      });
  });

  sheet.addRow(['']); 
  const delivryBoysrRow = sheet.addRow(['Delivery Boys', '', '', '', '', '', 'Amount']);
  delivryBoysrRow.font = { size: 14, bold: true };
  for (let i = 1; i <= 7; i++) {
    delivryBoysrRow.getCell(i).fill = styles.mainHead;
  }
  sheet.mergeCells(`A${delivryBoysrRow._number}:D${delivryBoysrRow._number}`);
  sheet.mergeCells(`G${delivryBoysrRow._number}:L${delivryBoysrRow._number}`);
  sheet.getCell(`G${delivryBoysrRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };

  deliveryBoysSummary?.forEach((partner, index) => {
    const partnerRow = sheet.addRow([partner?.partner, '', '', '', '', '', partner?.total?.toFixed(decimalPosition) || '0.0']);
    partnerRow.font = { size: 12, bold: true };
    sheet.mergeCells(`A${partnerRow._number}:D${partnerRow._number}`);
    sheet.mergeCells(`G${partnerRow._number}:L${partnerRow._number}`);
    sheet.getCell(`G${partnerRow._number}`).alignment = { vertical: 'middle', horizontal: 'right' };
    partnerRow.eachCell((cell) => cell.fill = styles.subHead);

    const tableHeaderRow = sheet.addRow(['Order No', '', '', '', 'Date', '', '', '', 'Amount', '', '', '']);
    tableHeaderRow.font = { bold: true, bold: true };
    sheet.mergeCells(`A${tableHeaderRow._number}:D${tableHeaderRow._number}`);
    sheet.mergeCells(`E${tableHeaderRow._number}:H${tableHeaderRow._number}`);
    sheet.mergeCells(`I${tableHeaderRow._number}:L${tableHeaderRow._number}`);

// Set alignment to center for the merged cells
    sheet.getCell(`A${tableHeaderRow._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
    sheet.getCell(`E${tableHeaderRow._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
    sheet.getCell(`I${tableHeaderRow._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; 

// Apply styles (if any) for the table header
  tableHeaderRow.eachCell((cell) => {
      cell.fill = styles.tableHead;
  });

    partner?.data?.forEach((order) => {
      
        const orderRow = sheet.addRow([order.orderNo, '', '', '', order.date, '', '', '', order.amount?.toFixed(decimalPosition) || '0.0']);
        const rowNumber = orderRow._number;

        sheet.mergeCells(`A${rowNumber}:D${rowNumber}`);
        sheet.mergeCells(`E${rowNumber}:H${rowNumber}`);
        sheet.mergeCells(`I${rowNumber}:L${rowNumber}`);

        sheet.getCell(`A${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
        sheet.getCell(`E${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
        sheet.getCell(`I${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };

    });
});

    sheet.addRow(['']); 
    const dicsount = sheet.addRow(['Discount', '', '', '', '', '', '']);
    dicsount.font = { size: 14, bold: true };
  
    const billTypeRows = sheet.addRow(['Bill Type', '', '', '', '', '', 'Amount']);
    billTypeRows.font = { size: 14, bold: true };
    for (let i = 1; i <= 7; i++) {
      billTypeRows.getCell(i).fill = styles.mainHead;
    }
    sheet.mergeCells(`A${billTypeRows._number}:D${billTypeRows._number}`);
    sheet.mergeCells(`G${billTypeRows._number}:L${billTypeRows._number}`);
    sheet.getCell(`G${billTypeRows._number}`).alignment = { vertical: 'middle', horizontal: 'right' };

    const dineInRows = sheet.addRow(['Dine In', '', '', '', '', '', discountSummary?.dineIn?.discount?.toFixed(decimalPosition) || '0.0']);
    dineInRows.font = { size: 12, bold: true };
    dineInRows.eachCell((cell) => cell.fill = styles.subHead);
    sheet.mergeCells(`A${dineInRows._number}:D${dineInRows._number}`);
    sheet.mergeCells(`G${dineInRows._number}:L${dineInRows._number}`);
    sheet.getCell(`G${dineInRows._number}`).alignment = { vertical: 'middle', horizontal: 'right' };
   
    const tableHeaderRows = sheet.addRow(['Order No', '', '', '', 'Date', '', '', '', 'Amount', '', '', '']);
    tableHeaderRows.font = { bold: true };

// Merge cells for Bill No, Date, and Amount columns
  sheet.mergeCells(`A${tableHeaderRows._number}:D${tableHeaderRows._number}`); 
  sheet.mergeCells(`E${tableHeaderRows._number}:H${tableHeaderRows._number}`); 
  sheet.mergeCells(`I${tableHeaderRows._number}:L${tableHeaderRows._number}`); 

// Set alignment to center for the merged cells
  sheet.getCell(`A${tableHeaderRows._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; // Bill No
  sheet.getCell(`E${tableHeaderRows._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; // Date
  sheet.getCell(`I${tableHeaderRows._number}`).alignment = { vertical: 'middle', horizontal: 'center' }; // Amount
 
// Apply styles (if any) for the table header
tableHeaderRows.eachCell((cell) => {
    cell.fill = styles.tableHead;
});
// If dine-in data exists, append rows for individual bills starting from A9 onwards
discountSummary?.dineIn?.data?.forEach((item, index) => {
  const dataRow = sheet.addRow([item.name, '', '', '', item.orderDate, '', '', '', item.discount.toFixed(decimalPosition), '', '', '']);

  const rowNumber = dataRow._number;

  // Merge cells for Bill No, Date, and Amount columns in the data rows
  sheet.mergeCells(`A${rowNumber}:D${rowNumber}`); 
  sheet.mergeCells(`E${rowNumber}:H${rowNumber}`); 
  sheet.mergeCells(`I${rowNumber}:L${rowNumber}`); 

  // Set alignment to center for the merged cells
  sheet.getCell(`A${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`E${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`I${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };

});

    // Take Away Section
    const takeAwayRows = sheet.addRow(['Take Away', '', '', '', '', '', discountSummary?.takeAway?.discount?.toFixed(decimalPosition) || '0.0']);
    takeAwayRows.font = { size: 12, bold: true };
    takeAwayRows.eachCell((cell) => cell.fill = styles.subHead);
    sheet.mergeCells(`A${takeAwayRows._number}:D${takeAwayRows._number}`);
    sheet.mergeCells(`G${takeAwayRows._number}:L${takeAwayRows._number}`);
    sheet.getCell(`G${takeAwayRows._number}`).alignment = { vertical: 'middle', horizontal: 'right' };

    // Table headings for Take Away section
    const takeAwayHeaderRows = sheet.addRow(['Order No', '', '', '', 'Date', '', '', '', 'Amount', '', '', '']);
    takeAwayHeaderRows.font = { bold: true };
    const takeRowNumbers=takeAwayHeaderRows._number
    // Merge cells for Bill No, Date, and Amount columns
    sheet.mergeCells(`A${takeRowNumbers}:D${takeRowNumbers}`); 
    sheet.mergeCells(`E${takeRowNumbers}:H${takeRowNumbers}`); 
    sheet.mergeCells(`I${takeRowNumbers}:L${takeRowNumbers}`); 
    
// Set alignment to center for the merged cells
   sheet.getCell(`A${takeRowNumbers}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
   sheet.getCell(`E${takeRowNumbers}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
   sheet.getCell(`I${takeRowNumbers}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
   
    takeAwayHeaderRows.eachCell((cell) => cell.fill = styles.tableHead);

    discountSummary?.takeAway?.data?.forEach((item) => {
      const dataRow =  sheet.addRow([item.name,"","","", item.orderDate,"","","", item.discount.toFixed(decimalPosition),"","",""]);
      const rowNumber = dataRow._number;

      sheet.mergeCells(`A${rowNumber}:D${rowNumber}`); 
      sheet.mergeCells(`E${rowNumber}:H${rowNumber}`); 
      sheet.mergeCells(`I${rowNumber}:L${rowNumber}`); 

      sheet.getCell(`A${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
      sheet.getCell(`E${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
      sheet.getCell(`I${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
    });
    // Delivery row
    const deliveryRows = sheet.addRow(['Delivery', '', '', '', '', '',  discountSummary?.delivery?.discount?.toFixed(decimalPosition) || '0.0']);
    deliveryRows.font = { size: 12, bold: true };
    deliveryRows.eachCell((cell) => cell.fill = styles.subHead);
    sheet.mergeCells(`A${deliveryRows._number}:D${deliveryRows._number}`);
    sheet.mergeCells(`G${deliveryRows._number}:L${deliveryRows._number}`);
    sheet.getCell(`G${deliveryRows._number}`).alignment = { vertical: 'middle', horizontal: 'right' };

    const takeAwayHeadRows = sheet.addRow(['Order No', '', '', '', 'Date', '', '', '', 'Amount', '', '', '']);
    takeAwayHeadRows.font = { bold: true };
    const takeRowNum=takeAwayHeadRows._number
    // Merge cells for Bill No, Date, and Amount columns
    sheet.mergeCells(`A${takeRowNum}:D${takeRowNum}`); 
    sheet.mergeCells(`E${takeRowNum}:H${takeRowNum}`); 
    sheet.mergeCells(`I${takeRowNum}:L${takeRowNum}`); 
    
// Set alignment to center for the merged cells
   sheet.getCell(`A${takeRowNum}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
   sheet.getCell(`E${takeRowNum}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
   sheet.getCell(`I${takeRowNum}`).alignment = { vertical: 'middle', horizontal: 'center' }; 
   
   takeAwayHeadRows.eachCell((cell) => cell.fill = styles.tableHead);

    discountSummary?.delivery?.data?.forEach((item) => {
      const dataRow =  sheet.addRow([item.name,"","","", item.orderDate,"","","", item.discount.toFixed(decimalPosition),"","",""]);
      const rowNumber = dataRow._number;

      sheet.mergeCells(`A${rowNumber}:D${rowNumber}`); 
      sheet.mergeCells(`E${rowNumber}:H${rowNumber}`); 
      sheet.mergeCells(`I${rowNumber}:L${rowNumber}`); 

      sheet.getCell(`A${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
      sheet.getCell(`E${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
      sheet.getCell(`I${rowNumber}`).alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // Export to Excel
    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'AdvancedShiftReport.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};

  const handleSubmit = () => {
    let validationErrors = {};
    if (!shiftReportBranch) {
      validationErrors.branch = "Please select a branch.";
    }
    if (!shiftReportShift) {
      validationErrors.shift = "Please select a shift.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidationAlert(validationErrors);
      return;
    }
    // submit  function  if no errors
    dispatch(set_shift_report_submit("submit"));
  };

  const branchOnchange = (e, newValue) => {
    dispatch(set_shift_report_branch(newValue));
    dispatch(set_shift_report_shift(null));
    viewShiftAPICall({ branchId: newValue?._id });
    setValidationAlert({});
  };
  useEffect(() => {
    userRole === "user" &&
      viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });
  }, []);

  return (
    <div className="global-page-parent-container">
      <div
        className="global-white-bg-container Header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 30px 0 25px",
          alignItems: "center",
        }}
      >
        <p>Advance Shift Report</p>
        <div>                       
          <ReactToPrint                               
            trigger={() => (                                 
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i                                                     
                  style={{                 
                    color: "white",
                    backgroundColor: " #d787e7",                 
                    fontSize: "medium",                          
                    padding: "8px",
                    borderRadius: "2px",               
                  }}                 
                  class="bi bi-printer"              
                ></i>
              </IconButton>
            )}
          />
                                                                                                                     
          <Tooltip title="Download">
            <IconButton onClick={exportToExcel}>
              <i
                class="bi bi-arrow-down-circle"
                style={{
                  backgroundColor: "rgb(255 118 133 / 69%)",
                  color: "white",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
              ></i>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div
        className="global-white-bg-container "
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          className="new-global-single-input auto-complete-new"
          style={{ width: "26%", paddingInline: "20px" }}
        >
          <Autocomplete
            disablePortal
            options={
              userRole === "admin" ? allBranchesList : allowedBranchList || []
            }
            getOptionLabel={(option) => option?.branchName}
            placeholder="Branch"
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Branch" focused placeholder="" />
            )}
            value={shiftReportBranch}
            onChange={branchOnchange}
          />
          <p style={{ marginRight: "23px" }} className="doc-validation-alert">
            {validationAlert?.branch}
          </p>
        </div>
        <div
          className="new-global-single-input auto-complete-new"
          style={{ width: "28%", padding: "0 55px 0 14px" }}
        >
          <Autocomplete
            disablePortal
            options={allShiftList?.slice(0)?.reverse() || []}
            getOptionLabel={(option) => option?.SHIFID}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Shift" focused placeholder="" />
            )}
            value={shiftReportShift}
            onChange={(e, newValue) => {
              dispatch(set_shift_report_shift(newValue));
              setValidationAlert({});
            }}
          />
          <p style={{ marginRight: "57px" }} className="doc-validation-alert">
            {validationAlert?.shift}
          </p>
        </div>
        <button className="create-button-blue" onClick={handleSubmit}>
          Submit
        </button>
      </div>
  

      <ShiftReportTable />
    </div>
  );
}

export default PosNewShiftReport;
