import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { verifyPasswordAuthentication } from '../../API/selfOrderAPIS';

const PasswordDialog = ({ open, handleClose }) => {

  const userInfo = useSelector((state) => state.loginResponseSlice.value);

  const [password, setPassword] = useState('');

  const handleConfirm = () => {
    verifyPasswordAuthentication({password},setPassword)
  };

  useEffect(()=>{
    setPassword("")
  },[open])
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent="center" alignItems="center">
          <span>Authentication</span>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              value={userInfo?.userName}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Password"
              type="text"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleConfirm()}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              color='error'
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="success"
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordDialog;
