import axios from "axios"
import { errorSnackMsg } from "../../component/Custom Hooks/SnackkBarUtilities"
import { setSubmitLoading } from "../../Redux/Dashboard/mainDashBoardSlice"
import store from "../../Redux/store"
import { HEADERS } from "../../API/UrlAndPaths"
import { selfDialogScreenSelected } from "../Slices/selfOrderMainSlice"

export const verifyPasswordAuthentication= async (
    body
    )=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("admin/verifypassword",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            let selfScreen=store.getState().selfOrderMainSlice.selfDialogScreen
                selfScreen==="passwordDialog" &&store.dispatch(selfDialogScreenSelected("menuDialog"))

            store.dispatch(setSubmitLoading(false))
        }
    })
    .catch((err)=>{
        errorSnackMsg(err?.response?.status===401?"Incorrect Password" : err.message || err.response.data)
        store.dispatch(setSubmitLoading(false))
    })
}