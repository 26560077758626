import React from 'react'
import SelectCard from '../Card/SelectCard'
import dineInImg from "../../../Assets/SelfOrder/dineIn.png"
import takeAwayImg from "../../../Assets/SelfOrder/Takeaway.png"
import { useDispatch } from 'react-redux'
import { setSelfOrderType } from './selfOrderEssentialsSlice'
import BackButton from '../Buttons/BackButton'
import { setScreenSelected } from '../../Slices/selfOrderMainSlice'
import { listAllFloorAPI } from '../../../component/Modules/FloorManagement/addSeat/floorManagementApi'
const OrderType = () => {
  const dispatch=useDispatch()

  const cardClick=(click)=>{
    dispatch(setSelfOrderType(click))
    click==="DINEIN" && listAllFloorAPI(); 
  }

  return (
    <div className="self-login-ctn">
        <BackButton onClick={() => dispatch(setScreenSelected(null))} />
        
        <h3>ORDER TYPE</h3>
        <div className="justify-center" style={{gap:"15px",flexWrap:"wrap"}}>
            <SelectCard image={dineInImg} label="DINE-IN" onClick={()=>cardClick("DINEIN")}/>
            <SelectCard image={takeAwayImg} label="TAKE-AWAY" onClick={()=>cardClick("TAKEAWAY")}/>
        </div>
    </div>
  )
}

export default OrderType