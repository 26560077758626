import { createSlice } from "@reduxjs/toolkit";

export const selfOrderEssentialsSlice=createSlice({
    name:"selfOrderEssentialsSlice",
    initialState:{
        ordType:null,
        mainCat:null,
        subCat:null,
        catSubList:[],
        subCatChildList:[],
        specialOfferDialog:false
    },
    reducers:{
      setSelfOrderType:(state,action)=>{
        state.ordType=action.payload;
      },
      setSelfMainCat:(state,action)=>{
        state.mainCat=action.payload;
      },
      setSelfSubCat:(state,action)=>{
        state.subCat=action.payload;
      },
      setCatSubList:(state,action)=>{
        state.catSubList=action.payload;
      },
      setSubCatChildList:(state,action)=>{
        state.subCatChildList=action.payload;
      },
      setSpecialOfferDialog:(state,action)=>{
        state.specialOfferDialog=action.payload;
      },
    }
})

export const {
    setSelfOrderType,setSelfMainCat,setSelfSubCat,
    setCatSubList,setSubCatChildList,setSpecialOfferDialog
}=selfOrderEssentialsSlice.actions

export default selfOrderEssentialsSlice.reducer