import React, { useRef, useState } from 'react'
import TableRestaurantTwoToneIcon from "@mui/icons-material/TableRestaurantTwoTone";
import MenuButton from '../Buttons/MenuButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { IconButton, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchBarInput } from '../../../component/Modules/Sales/Billing/Components/topBarSliceBilling';
import { setSelfOrderType } from '../Order/selfOrderEssentialsSlice';
const SelfOrderTopBar = () => {

    const dispatch=useDispatch();
    const isMobile = useMediaQuery('(max-width:600px)');
    const inputRef = useRef(null);
    const {searchBarInput}=useSelector((state)=>state.topBarSliceBilling)

    const [iconClick, setIconClick] = useState({search:false,cart:false})
    const [hideContents, setHideContents] = useState(false)

    const searchIconOnClick = () => {
        setIconClick((prevState) => {
            const newState = { ...prevState, search: !prevState.search,cart:false };
            if (newState.search) {
              setTimeout(() => {
                inputRef.current?.focus(); 
              }, 0);
            }
            return newState;
          });

        if (isMobile) {
            setHideContents(!iconClick?.search);
        }
    };
    const cartIconOnClick = () => {
        setIconClick({ ...iconClick, cart: !iconClick.cart })
        if (isMobile) {
            setHideContents(!iconClick?.cart);
        }
    };
   


    return (
        <div className='self-topbar-ctn'>
            <MenuButton />

            {
                <div className="customer-ctn justify-center">
                    <IconButton>
                        <PersonOutlineIcon style={{ border: "2px solid #fff", color: "#fff", borderRadius: "50%" }} className='responsive-arrow-icon' />
                    </IconButton>
                    <h4>Munshid</h4>
                </div>}
            {
                !(hideContents) &&
                <div className="table-ctn justify-center">
                    <TableRestaurantTwoToneIcon className='responsive-arrow-icon' style={{ color: "#e11503" }} />
                    <p>: T7</p>
                </div>}

            <div className="search-ctn justify-center">
                <SearchIcon
                    onClick={searchIconOnClick}
                    className="responsive-arrow-icon"
                    style={{ color: "#e11503" }}
                />
                {(!isMobile || iconClick?.search) && (
                    <input
                        ref={inputRef}
                        type="text"
                        value={searchBarInput}
                        onBlur={searchIconOnClick}
                        placeholder='Search'
                        onChange={(e)=>dispatch(setSearchBarInput(e.target.value))}
                    />
                )}

               {
               (isMobile && searchBarInput) &&
               <span className="self-order-active-span"></span>}
            </div>

            <div className="search-ctn cart-ctn justify-center" onClick={()=>dispatch(setSelfOrderType("cartSection"))}>
                <ShoppingCartOutlinedIcon
                    onClick={cartIconOnClick}
                    className="responsive-arrow-icon"
                    style={{ color: "#e11503" }}
                />
                {(!isMobile || iconClick?.cart) && (
                    <p>300.00 AED</p>
                )}
            </div>
        </div>
    )
}

export default SelfOrderTopBar