import React from 'react'
import SelfOrderFooter from '../Navbar/SelfOrderFooter'
import CategoryListingScroll from './CategoryListingScroll'
import ProductCardContainer from './ProductCardContainer'
import SubCategoryandSpecialItems from './SubCategoryandSpecialItems'
import SelfSpecialOffersDialog from './SelfSpecialOffersDialog'

const SelfProductLists = () => {

    const backBtnFunction=()=>{

    }
  return (
    <div>
        <div className='self-order-middle-container'>
            <CategoryListingScroll/>
            <div className="flex-column" style={{flex:"1"}}>
              <SubCategoryandSpecialItems/>
              <ProductCardContainer/>
            </div>
        </div>
        <SelfOrderFooter blackBtnText={"BACK"} redBtnText={"NEXT"} blackBtnOnclick={backBtnFunction}/>
   
    <SelfSpecialOffersDialog/>
    </div>
  )
}

export default SelfProductLists