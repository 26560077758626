import { Autocomplete, TextField } from '@mui/material'
import React from 'react'

const SelfOrderInput = ({
    label,
    value,
    onChange,
    placeholder = '',
    type = 'text',
    className = '',
    error = '',
    ...props
  }) => {
  return (
      <div className="self-order-form-control">
          <input className={`self-order-input self-order-input-alt ${className}`}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            type {...props}/>
          {/* <Autocomplete
              options={[]}
              getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
              renderInput={(params) => (
                  <TextField {...params} placeholder="select Branch" />
              )}
          /> */}
          <span className="self-order-input-border self-order-input-border-alt"></span>
      </div>
  )
}

export default SelfOrderInput