import React, { useEffect } from 'react'
import  "./CSS/selfOrderParent.css"
import SelfLoginPage from './Components/LoginSelf/SelfLoginPage'
import { useDispatch, useSelector } from 'react-redux'
import SelfRegisterPage from './Components/LoginSelf/SelfRegisterPage'
import MenuButton from './Components/Buttons/MenuButton'
import LoginType from './Components/LoginSelf/LoginType'
import { selfDialogScreenSelected, setScreenLoginType } from './Slices/selfOrderMainSlice'
import PasswordDialog from './Components/Dialog/PasswordDialog'
import MenuDialog from './Components/Dialog/MenuDialog'
import SelfOrderSection from './Components/Order/SelfOrderSection'
import { categoryListAPICall } from '../component/Modules/Inventory/Configuration/Category/CategoryAPI'
const SelfOrderScreen = () => {

  const dispatch=useDispatch()
  const {screenSelected,loginType,selfDialogScreen}=useSelector((state)=>state.selfOrderMainSlice)
  const selfUser=localStorage.getItem('selfUser')

  const closeDialog=()=>{
    dispatch(selfDialogScreenSelected(null))
  }
  useEffect(()=>{
    dispatch(setScreenLoginType(selfUser))
    categoryListAPICall();
  },[])

  return (
    <div className='self-order-parent-ctn'>
          {
            loginType==="selfUser"
            ?<LoginType/>
            :
            screenSelected==="orderScreen"?
            <SelfOrderSection/>:
            screenSelected==="registerScreen"?
            <SelfRegisterPage />
            : <SelfLoginPage />
          }

       {!(screenSelected==="orderScreen") && <MenuButton/>}
        <PasswordDialog open={selfDialogScreen==="passwordDialog"} handleClose={closeDialog}/>
        <MenuDialog open={selfDialogScreen==="menuDialog"} handleClose={closeDialog}/>
    </div>
  )
}

export default SelfOrderScreen