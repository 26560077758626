import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { getFloorDetails } from "../Slice/billingFloorPlanSlice"
import { setBtnSelected, setDefaultCustomer, setOngoingOrderCount } from "../Components/topBarSliceBilling"
import { setBillLoading, setErrorSnack, setOrderIdInPOS, setSnackMsg, setSuccessSnack } from "../Slice/billingScreenSlice"
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice"

// to get floor details
export const getFloorDetailsForBilling=async(body)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/viewFloorDesignForPos",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(getFloorDetails(res.data))
            store.dispatch(setSubmitLoading(false))
        }
    }
    )
    .catch((err)=>{
            store.dispatch(setSubmitLoading(false))
            store.dispatch(getFloorDetails(undefined))
            if(err.response.status===500){
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            }else{
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))
        })

}

// add order from floorplan
export const addOrderFromFloorAPI=async(body)=>{
    store.dispatch(setBillLoading(true))                
    await axios.post("pos/addPosOrderFromFloorPlan",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(setOngoingOrderCount(res?.data?.ongoing || 0))

            store.dispatch(setBillLoading(false))                
            store.dispatch(setOrderIdInPOS(res?.data?._id))
            store.dispatch(setBtnSelected(null))
            store.dispatch(setSnackMsg("Order Added"))
            store.dispatch(setSuccessSnack(true))
        }
    }
    )
    .catch((err)=>{
            store.dispatch(setBillLoading(false))                
            if(err.response.status===500){
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            }else{
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))
        })

}
export const updateOrderTablesAPI=async(body)=>{
    store.dispatch(setBillLoading(true))                
    await axios.post("pos/transferTable",body,HEADERS)
    .then((res)=>{
        if(res.status===200){

            store.dispatch(setBillLoading(false))                
            store.dispatch(setOrderIdInPOS(null))
            setTimeout(() => {
                store.dispatch(setOrderIdInPOS(res?.data?._id))
            }, 0);
            store.dispatch(setBtnSelected(null))
            store.dispatch(setSnackMsg("Table Updated"))
            store.dispatch(setSuccessSnack(true))
        }
    }
    )
    .catch((err)=>{
            store.dispatch(setBillLoading(false))                
            if(err.response.status===500){
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            }else{
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))
        })

}

// default customer api
export const defaultCustomerForPOSAPI=async(body)=>{
    await axios.post("customer/viewCashCustomer",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(setDefaultCustomer(res.data))
        }
    })
    .catch((err)=>{
        store.dispatch(setDefaultCustomer(undefined))
    })

}