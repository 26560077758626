import React, { useState } from 'react'
import SelfOrderInput from '../Input/SelfOrderInput'
import SelfLoginBtn from '../Buttons/SelfLoginBtn'
import BackButton from '../Buttons/BackButton'
import { useDispatch } from 'react-redux'
import { setScreenSelected } from '../../Slices/selfOrderMainSlice'
const SelfRegisterPage = () => {

    const dispatch = useDispatch()
    const [expand, setExpand] = useState(false)

    const inputsData = [
        { id: 'name', placeholder: 'Name' },
        { id: 'mobile', placeholder: 'Mobile' },
        { id: 'city', placeholder: 'City' },
        { id: 'street', placeholder: 'Street' },
        { id: 'buildingName', placeholder: 'Building Name' },
        { id: 'buildingNo', placeholder: 'Building No' },
        { id: 'roomNo', placeholder: 'Room No' },
    ];

    const [formValues, setFormValues] = useState({
        mobile: '',
        name: '',
        number: '',
        class: '',
        city: '',
        street: '',
        buildingName: '',
        buildingNo: '',
        roomNo: '',
    })

    const handleInputChange = (key) => (e) => {
        setFormValues({ ...formValues, [key]: e.target.value })
    }
    return (
        <div className='self-login-ctn'>
            <BackButton onClick={() => dispatch(setScreenSelected(null))} />
            <div className="self-login-input-ctn">
                <h3>REGISTER</h3>
                {
                    !expand ?
                        <div className="self-login-body">
                            <SelfOrderInput
                                value={formValues?.name}
                                onChange={handleInputChange("name")}
                                placeholder="Name"
                            />
                            <SelfOrderInput
                                value={formValues?.mobile}
                                onChange={handleInputChange("mobile")}
                                placeholder="Mobile"
                            />
                            <p className='red-action' onClick={() => setExpand(true)}>Add More Details +</p>
                        </div>
                        :
                        <div className="self-register-input-ctn">
                            {inputsData.map((input) => (
                                <SelfOrderInput
                                    key={input.id}
                                    value={formValues[input.id]}
                                    onChange={handleInputChange(input.id)}
                                    placeholder={input.placeholder}
                                />
                            ))}
                        </div>
                }
                <SelfLoginBtn
                    label='Register'
                />

                <p className="skip" onClick={()=>dispatch(setScreenSelected("orderScreen"))}>SKIP</p>
            </div>

        </div>
    )
}

export default SelfRegisterPage