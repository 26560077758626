import axios from "axios"
import { HEADERS } from "../../../API/UrlAndPaths"
import store from "../../../Redux/store"
import { setSubmitLoading } from "../../../Redux/Dashboard/mainDashBoardSlice"
import { errorSnackMsg } from "../../../component/Custom Hooks/SnackkBarUtilities"
import { setCatSubList, setSubCatChildList } from "./selfOrderEssentialsSlice"
import { get_products_list_for_billing, setCartProductType } from "../../../component/Modules/Sales/Billing/newBillingSlice"

export const initialSubCatFilterAPI=async(body)=>{
    try{
        store.dispatch(setSubmitLoading(true))
        const response=await axios.post("readymade/categoryfilter",body,HEADERS)

        if(response.status===200){
            store.dispatch(body?.parentCategories?setSubCatChildList(response.data):setCatSubList(response.data))
        }
    } catch(error){
        store.dispatch(body?.parentCategories?setSubCatChildList([]):setCatSubList([]))
        errorSnackMsg(error.message)
    }
    finally{
        store.dispatch(setSubmitLoading(false))
    }
}
export const comboOffersProductsListForSelfOrder=async(body,succcesFn)=>{

    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/viewSpecialOfferCombos",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
                store.dispatch(setSubmitLoading(false))
                store.dispatch(get_products_list_for_billing({billingProductsList:res.data}))
                succcesFn()
                store.dispatch(setCartProductType(3))
            }
        })
        .catch((err)=>{
            
            store.dispatch(setSubmitLoading(false))
            store.dispatch(get_products_list_for_billing({billingProductsList:[]}))
            errorSnackMsg(err.message)
        })
}

export const specialOfferProductsListForSelf=async(body,succcesFn)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/viewSpecialOfferById",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                succcesFn()
                store.dispatch(get_products_list_for_billing({billingProductsList:res.data}))
                store.dispatch(setCartProductType(2))
                store.dispatch(setSubmitLoading(false))
            }
        })
        .catch((err)=>{
            store.dispatch(setSubmitLoading(false))
            errorSnackMsg(err.response.data)
            store.dispatch(get_products_list_for_billing({billingProductsList:[]}))
        })
}