import React, { useState } from 'react'
import { Dialog, DialogContent, DialogActions, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dineInImg from "../../../Assets/SelfOrder/dineIn.png"
import dummyProductItemImg from "../../../Assets/BillingImages/emptyFoodImg.svg"
import { useDispatch, useSelector } from 'react-redux';
import { setSpecialOfferDialog } from './selfOrderEssentialsSlice';
import BackButton from '../Buttons/BackButton';
import { comboOffersProductsListForSelfOrder, specialOfferProductsListForSelf } from './selfOrderListingAPI';
import ComboPriceTag from '../../../component/Modules/Sales/SpecialItems/components/ComboPriceTag';
const SelfSpecialOffersDialog = ({ onSelect}) => {

  const dispatch = useDispatch()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const decimalPosition=localStorage.getItem('decimalPosition')

  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const userBranchId = userInfo?.branchPk;

  const { specialItemsListForBilling } = useSelector((state) => state.specialItemsSlice);
  const { specialOfferDialog } = useSelector((state) => state.selfOrderEssentialsSlice);
  const { productsList, productType } = useSelector((state) => state.newBillingSlice);

  const [productsListing, setProductsListing] = useState(false)
  const cardClick = (item)=>() => {
    let succcesFn=()=>{
      setProductsListing(typeof(item) === 'string'?"COMBO OFFERS":item?.name)
    }
      if(typeof(item) === 'string'){
        comboOffersProductsListForSelfOrder({branchId:userBranchId},succcesFn)
      }else{
        specialOfferProductsListForSelf(
          {
            id: item?._id,
            branchId: userBranchId,
          },
          succcesFn
        );
      }
  }

  const closeDialog = () => {
    dispatch(setSpecialOfferDialog(false))
    setProductsListing(false)
  }

  const bacBtnClick = () => {
    productsListing?setProductsListing(false): closeDialog()
  }
  return (
    <Dialog
      open={specialOfferDialog}
      onClose={closeDialog}
      fullWidth
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          display: 'flex',
          flexDirection: 'column',
          maxHeight: fullScreen ? '80vh' : '60vh',
          paddingBottom:!productsListing&&"5%"
        },
      }}
      className='self-order-special-item-ctn'
    >

      <BackButton onClick={bacBtnClick} />
      <h3>{productsListing || "SPECIAL ITEMS"}</h3>

      <DialogContent
        sx={{
          overflowY: 'auto',
          padding: 2,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {
          productsListing ?
            <div className="self-product-card-ctn">
             {
             productsList?.map((product,index)=>(

              <div
              className="self-product-card-item"
              key={index}
            //   onClick={() => addToCart(product)}
            >
              <img
                 src={product?.imageUrl && product?.imageUrl.length !== 0
                  ? product?.imageUrl
                  : dummyProductItemImg}
                alt="No Img"
                className="product-card-image"
              />
              <h5>{product.productName}</h5>
              {productType === 3 ? (
                   product?.productList?.map((item, i) => (
                     <p style={{ display: "flex", flexWrap: "wrap" }} key={i}>
                       <span style={{ overflow: "hidden" }}>
                         {item?.productName}
                       </span>
                       <span style={{ marginLeft: "auto" }}>
                         {item.qty}
                       </span>
                     </p>
                   ))
                 ) : (
                   <p>
                     {product.salesPrice?.toFixed(decimalPosition)}
                   </p>
                 )}
              
              {productType === 3 && (
                            <div className="offer-price" style={{ right: "0px" }}>
                                <ComboPriceTag
                                    amount={product.salesPrice?.toFixed(decimalPosition)}
                                />
                            </div>
                        )}
              </div>
             ))
            }
            </div>
            :
            <div className="special-items-card-list">
              <div className='self-order-special-item-card' onClick={cardClick("combo")}>
                <img src={dineInImg} alt="" />
                <h4>{"COMBO OFFERS"}</h4>
              </div>
              {
                specialItemsListForBilling?.map((item, index) => (
                  <div key={index} onClick={cardClick(item)} className='self-order-special-item-card'>
                    <img src={dineInImg} alt="" />
                    <h4>{item?.name}</h4>
                  </div>
                ))
              }
            </div>
        }
      </DialogContent>

      {
      productsListing &&  
      <DialogActions sx={{
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: 2,
        position: 'sticky', // Keeps button at the bottom
        bottom: 0,
        backgroundColor: 'white',
        justifyContent: 'end',
      }}>
        <button className='self-order-button btn-next' onClick={onSelect}>
          Select
        </button>
      </DialogActions>}
    </Dialog>
  )
}

export default SelfSpecialOffersDialog