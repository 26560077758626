import React from 'react'

const SelfLoginBtn = ({ label = 'BUTTON', onClick, className = '', icon = '', ...props }) => {
  return (
    <button className={`self-order-login-btn ${className}`} onClick={onClick} {...props}>
        <i className="self-order-login-animation"></i>
            {label}
        <i class="self-order-login-animation"></i>
    </button>
  )
}

export default SelfLoginBtn