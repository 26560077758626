import React from 'react'
import emptyCartImg from '../../../Assets/BillingImages/emptyCart.svg'

const EmptyCart = () => {
  return (
      <div className="self-cart-container">
          <img className='empty-cart-svg' src={emptyCartImg} alt="" />
          <p className='label'>Your cart is empty</p>
      </div>
  )
}

export default EmptyCart