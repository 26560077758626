import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  IconButton,
  useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import ShiftTimerComponent from '../../../component/Single Components/ShiftTimerComponent';
import { selfDialogScreenSelected, setScreenLoginType } from '../../Slices/selfOrderMainSlice';

const MenuDialog = ({ open, handleClose }) => {
  const theme = useTheme();
  const dispatch=useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const shiftStatus = useSelector((state) => state.shiftStatusSlice.value);

  const loginChangeClick=()=>{
    localStorage.setItem("selfUser","selfUser")
    dispatch(setScreenLoginType("selfUser"))
    dispatch(selfDialogScreenSelected(null))
  }

  const logoutClick=()=>{
    const printValue = localStorage.getItem("PrinterSettings");
    localStorage.clear();
    if (printValue !== null) {
      localStorage.setItem("PrinterSettings", printValue);
    }
    window.location.replace("/");
  }
  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="xs" 
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
          SHIFT {shiftStatus?shiftStatus?.shiftId:"not found"}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Typography variant="h4" margin={0}>
            <ShiftTimerComponent/>
          </Typography>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={6} container justifyContent={isMobile?"end":"center"}>
            {isMobile ? (
              <IconButton onClick={loginChangeClick} color="primary" sx={{backgroundColor:"#e7e7e7"}}>
                <SwitchAccountIcon />
              </IconButton>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={loginChangeClick}
              >
                Change Login Type
              </Button>
            )}
          </Grid>
          <Grid item xs={6} container justifyContent={isMobile?"start":"center"}>
            {isMobile ? (
              <IconButton 
              onClick={logoutClick}
              color="error" sx={{backgroundColor:"#e7e7e7"}}>
                <LogoutIcon />
              </IconButton>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={logoutClick}
              >
                Logout
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default MenuDialog;
