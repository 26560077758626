import React from 'react'
import './selfOrderCart.css'
import SelfOrderFooter from '../Navbar/SelfOrderFooter'
import BackButton from '../Buttons/BackButton'
import EmptyCart from './EmptyCart'
const SelfOrderCart = () => {

    const bacBtnClick=() => {}
  return (
      <div>
          <div className='self-order-middle-container'>
              <BackButton onClick={bacBtnClick} />
              <EmptyCart />
          </div>
          <SelfOrderFooter blackBtnText={"ADD MORE +"} redBtnText={"NEXT"} />
      </div>
  )
}

export default SelfOrderCart