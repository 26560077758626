import React from 'react'
import SelfOrderFloorPlan from './SelfOrderFloorPlan'
import "../../CSS/selfOrderSection.css"
import { useSelector } from 'react-redux'
import OrderType from './OrderType'
import SelfOrderTopBar from '../Navbar/SelfOrderTopBar'
import SelfProductLists from './SelfProductLists'
import SelfOrderCart from '../Cart/SelfOrderCart'
const SelfOrderSection = () => {

  const {ordType}=useSelector((state)=>state.selfOrderEssentialsSlice)

  return (
    <>
        { (ordType==="orderProductLists" || ordType==="cartSection") &&<SelfOrderTopBar/>}
        {
          ordType==="DINEIN"?
          <SelfOrderFloorPlan/>:
          ordType==="orderProductLists"?
          <SelfProductLists/>:
          ordType==="cartSection"?
          <SelfOrderCart/>:
          <OrderType/>
        }        

    </>
  )
}

export default SelfOrderSection