import { Box, CircularProgress, IconButton, Tab, Tabs } from '@mui/material'
import { rgba } from 'polished';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedElementDetails } from '../../../FloorManagement/addSeat/addSeatSlice';
import { Rnd } from 'react-rnd';
import { shapeTypes, structures } from '../../../FloorManagement/utils/floorManagementEnum';
import LineSegmentTool from '../../../FloorManagement/components/icons/LineSegmentTool';
import StarTool from '../../../FloorManagement/components/icons/StarTool';
import Chair from '../../../FloorManagement/components/icons/Chair';
import TopBar from './TopBar';
import { addOrderFromFloorAPI, getFloorDetailsForBilling, updateOrderTablesAPI } from '../API/billingFloorSection';
import { getCurrentTime, today } from '../../../../../Js/Date';
import { setErrorSnack, setOrderIdInPOS, setSnackMsg } from '../Slice/billingScreenSlice';
import { setBtnSelected } from './topBarSliceBilling';
import useTabWidth from '../../../FloorManagement/hooks/useTabWidth';
import HomeIcon from '@mui/icons-material/Home';
import { listHeldOrders } from '../API/viewOrderSection';

function BillingFloorPlan() {

    const dispatch=useDispatch()

    const userShiftStatus = localStorage.getItem("shiftStatus");
    const userShiftpk = JSON.parse(userShiftStatus)?._id;

    const defaultFloorId=localStorage.getItem("defaultFloorId");

    // login response
    const userInfo = useSelector((state) => state.loginResponseSlice.value);
   
   // all floor list of a branch
    const { floors } = useSelector((state) => state.addSeatSlice);
    const tabWidth = useTabWidth(floors?.length);


    const { selectedFloorDetails } = useSelector((store) => store.billingFloorPlanSlice);
    const { customer,defaultCustomer,btnClicked } = useSelector((store) => store.topBarSliceBilling);
    const {orderIdForPos,singleOrderInfo} = useSelector((store) => store.billingScreenSlice);
    const {branchConfigurationList} = useSelector((state) => state.newBillingSlice);
    const [floorSelected, setFloorSelected] = useState(null)
    const [floorLoading,setFloorLoading]=useState(false)
    const [selectedTables,setSelectedTables]=useState([])
    const [selectedChairs,setSelectedChairs]=useState([])
    const [floorId,setFloorId]=useState(null)

    const [orderIdColorMapping, setOrderIdColorMapping] = useState({});

    const [tableOrder,setTableOrders] = useState([])
    const [initialColors,setInitialColors]=useState({})
    // Use polished's rgba function to decrease opacity of selected floor
    const getSelectedTabColor = (originalColor) => {
        try {
            return rgba(originalColor, 0.5);
        } catch (error) {
            return rgba("#f67878", 0.5);
        }
    };

    // Set floor tab details
   const handleTabDetails = (res) => {

    dispatch(setSelectedElementDetails({ ...res, isFloor: true }));
    getFloorDetailsForBilling({ id: res._id,branchId:userInfo?.branchPk});
    setFloorId(res._id)
    localStorage.setItem("defaultFloorId",res._id)
    setSelectedTables([]);
    setSelectedChairs([])
  };

//   function -chair and table-selection
    const handleShapeSelected = (shape)  => {
        if(shape.isUsed){
            if(selectedTables.length!==0){
                dispatch(setSnackMsg("Seat already in use !!"))
                dispatch(setErrorSnack(true))
            }else{
                dispatch(setOrderIdInPOS(orderIdForPos=== getFirstObject(shape?.orders)?null:getFirstObject(shape?.orders)))
            }
        }
        else if(orderIdForPos===null){
        if (shape.type === "CHAIR") {
            let seatSelection=selectedFloorDetails?.seatSelection
            if(seatSelection){
                const chairSelected = selectedChairs.some((obj) => obj === shape?._id);
                if (chairSelected) {
                    const updatedSelectedObjects = selectedChairs.filter((obj) => obj !== shape._id);
                    setSelectedChairs(updatedSelectedObjects);
                    // setSelectedTables(updatedSelectedTable)
                } else {
                    setSelectedChairs([...selectedChairs, shape._id]);
                    setSelectedTables([...selectedTables, shape.tableId]);
                }
            }else{
                dispatch(setSnackMsg("Chair selection disabled for this floor"))
                dispatch(setErrorSnack(true))
            }
           
        }
        else if(shape.type==="TABLE"){
            const tableSelected = selectedTables.some((obj) => obj === shape._id);
            if (tableSelected) {
                const updatedSelectedObjects = selectedTables.filter((obj) => obj !== shape._id);
                const filteredChairs = selectedChairs.filter(chairId => {
                    const matchingObject = selectedFloorDetails?.floorCanvas.find(obj => obj._id === chairId);
                    return !matchingObject || matchingObject.tableId !== shape._id;
                  });

                setSelectedTables(updatedSelectedObjects);
                setSelectedChairs(filteredChairs)
            } else {
                setSelectedTables([...selectedTables, shape._id]);

                // Find matching Chairs based on the selected tableId 
                const filteredIds = selectedFloorDetails?.floorCanvas
                    .filter((obj) => obj.tableId === shape._id)
                    .map((obj) => obj._id);

                const uniqueIds = new Set(selectedChairs.concat(filteredIds));

                uniqueIds.size !== 0 && setSelectedChairs([...uniqueIds]);
            }
        }
        }
    }   
//   function -chair and table-update on an order
    const tableShiftClicked = (shape)=> {


        // let existingTable=selectedTables. 
        
        let existing= selectedTables.includes(shape.tableId || shape._id) || getFirstObject(shape?.orders)===orderIdForPos


        if((shape.isUsed && !existing)){
                dispatch(setSnackMsg("Seat already in use !!"))
                dispatch(setErrorSnack(true))
        }
        else{
           
        if (shape.type === "CHAIR") {
            const chairSelected = selectedChairs.some((obj) => obj === shape._id);
            if (chairSelected) {
                const updatedSelectedObjects = selectedChairs.filter((obj) => obj !== shape._id);
                setSelectedChairs(updatedSelectedObjects);
                // setSelectedTables(updatedSelectedTable)
            } else {
                setSelectedChairs([...selectedChairs, shape._id]);
                setSelectedTables([...selectedTables, shape.tableId]);
            }
        }
        else if(shape.type==="TABLE"){
            const tableSelected = selectedTables.some((obj) => obj === shape._id);
            if (tableSelected) {
                const updatedSelectedObjects = selectedTables.filter((obj) => obj !== shape._id);
                const filteredChairs = selectedChairs.filter(chairId => {
                    const matchingObject = selectedFloorDetails?.floorCanvas.find(obj => obj._id === chairId);
                    return !matchingObject || matchingObject.tableId !== shape._id;
                  });

                setSelectedTables(updatedSelectedObjects);
                setSelectedChairs(filteredChairs)
            } else {

                setSelectedTables([...selectedTables, shape._id]);

                // Find matching Chairs based on the selected tableId 
                const filteredIds = selectedFloorDetails?.floorCanvas
                    .filter((obj) => obj.tableId === shape._id)
                    .map((obj) => obj._id);

                const uniqueIds = new Set(selectedChairs.concat(filteredIds));

                setSelectedChairs([...uniqueIds]);
            }
        }
        }
    }   


    const addOrderFloorPlan=()=>{
        const payload={
            branchId: userInfo?.branchPk,
            tableId:selectedTables,
            chairId:selectedChairs,
            orderDate:`${today} ${getCurrentTime()}`,
            cusId:customer?._id || defaultCustomer?._id,
            subTotal: 0,
            amtTotal: 0,
            floorId:floorId
        }


        addOrderFromFloorAPI(payload)
    }
    const updateTableSelected=()=>{
        const payload={
            orderId: orderIdForPos,
            tableId:selectedTables,
            chairId:selectedChairs,
            branchId: userInfo?.branchPk,
            floorId:floorId
        }

        if(selectedTables.length===0){
            dispatch(setSnackMsg("No seats selected !!"))
            dispatch(setErrorSnack(true))
        }else{
            updateOrderTablesAPI(payload)
        }
    }

    const updateMultiOrder=(id)=>{
        dispatch(setOrderIdInPOS(id))
        setTimeout(() => {
            updateOrderFloorPlan()
        }, 0);
    }
    const updateOrderFloorPlan=()=>{
        dispatch(setBtnSelected(null))
    }

    const getRandomColor = () => {
        const generateRandomValue = () => Math.floor(Math.random() * 200); 
        const color = `rgb(${generateRandomValue()}, ${generateRandomValue()}, ${generateRandomValue()})`;
    
        return color;
    };

    const getFirstObject=(list)=>{
        if(list.length!==0){
            return list[0]?._id
        }else{
            return null
        }
    }

    useEffect(()=>{
        if(selectedChairs.length!==0){
            const chairNames = selectedFloorDetails?.floorCanvas
            .filter(obj => selectedChairs.includes(obj._id))
            .map(obj => obj.tableId);
            const uniqueTableIds = [...new Set(chairNames)];

            setSelectedTables(uniqueTableIds);
        }
        // else{
        //     setSelectedTables([])
        // }
    },[selectedChairs])

    useEffect(() => {
        if (Object.keys(initialColors).length === 0) {
          setInitialColors(orderIdColorMapping);
        } else {
          setOrderIdColorMapping(initialColors);
        }
      
        const newColorValue = "rgb(255, 0, 0)";
      
        setOrderIdColorMapping((prevColors) => {
          if (prevColors.hasOwnProperty(orderIdForPos)) {
            // Update the color value for the orderIdForPos key
            return { ...prevColors, [orderIdForPos]: newColorValue };
          } else {
            return prevColors;
          }
        });
      }, [orderIdForPos]);

      useEffect(()=>{

        const index = floors.findIndex(obj => obj._id === defaultFloorId);

        if(floors?.length!==0){
            let inFloor=index>=0?index:false;
            setFloorSelected(inFloor || 0)
            dispatch(setSelectedElementDetails({ ...floors[inFloor || 0], isFloor: true }));
            getFloorDetailsForBilling({ id: floors[inFloor || 0]?._id,branchId:userInfo?.branchPk });
            setFloorId(floors[inFloor || 0]?._id || null)
        }
      },[floors])

      useEffect(()=>{
        if(btnClicked==="tableChange"){
            setSelectedChairs(singleOrderInfo?.chairId || [])
            if(singleOrderInfo?.chairId.length===0){
                setSelectedTables(singleOrderInfo?.tableId || [])
            }
            // setFloorRendered(true)
        }
      },[])


    //   Setting Orders under the selectedTable

    useEffect(()=>{
        let orderLists=selectedFloorDetails?.floorCanvas?.filter((obj)=>(
            selectedTables.includes(obj?._id)
        ))?.reduce((acc, obj) => acc.concat(obj.orders), []);
        setTableOrders(orderLists)
    },[selectedTables])

  return (
      <div className='billing-main-container'>
          <div className="billing-left-container" style={{width:"100%"}}>
            {/* <TopBar/> */}
            <div className="flex-row" style={{gap:"5px"}}>
              <div className='justify-center' style={{backgroundColor:"#fff",width:"40px"}}>
                  <IconButton
                    onClick={()=>{
                        dispatch(setBtnSelected(null))
                        dispatch(setOrderIdInPOS(null))
                    }}
                    >
                      <HomeIcon sx={{ color: "green" }} />
                  </IconButton>
              </div>
              <TopBar />
          </div>
              <div className="add-seat-wrapper" style={{ margin: "5px 0", height: "85vh",position:"relative" }}>
                  <Box
                      sx={{ maxWidth: "100%", width: "100%", bgcolor: "background.paper" }}
                  >
                      <Tabs
                          value={floorSelected}
                          onChange={(e, newValue) =>{ 
                            setFloorSelected(newValue)
                        }}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                          className="floor-container"
                          sx={{
                              "& .MuiTabs-indicator": {
                                  backgroundColor: `${"red"} !important`,
                              },
                          }}
                      >
                          {floors?.map((res, i) => (
                              <Tab
                                  key={i}
                                  sx={{
                                      backgroundColor:
                                          getSelectedTabColor(res?.color) || rgba("#f67878", 0.5),
                                      minWidth: floors?.length,
                                      color: "white",
                                      "&.Mui-selected": {
                                          backgroundColor: res?.color || "#f67878",
                                          color: "white",
                                      },
                                      minWidth: tabWidth
                                  }}
                                  className="floor-box"
                                  label={res?.nameOfFloor}
                                  onClick={() => handleTabDetails(res)}
                              />
                          ))}
                      </Tabs>
                      {
                          floorLoading ?
                              <div className="center-div">
                                  <CircularProgress size={100} color="primary" sx={{ outline: "none" }} />
                              </div>
                              :
                              <>
                                  {!floorSelected === null ? <div className="center-div">Select a floor</div> :
                                      <>
                                          {
                                              selectedFloorDetails?.floorCanvas.length === 0 ? <div className="center-div">No Floor plan added</div> :
                                                  <div className="tab-panel-container">
                                                      <div
                                                          className="sub-tab-wrapper"
                                                      >
                                                          {selectedFloorDetails?.floorCanvas.map((shape) => (
                                                              <Rnd
                                                                  key={shape._id}
                                                                  size={{
                                                                      width: shape.size.width,
                                                                      height: shape.size.height,
                                                                  }}
                                                                  position={{
                                                                      x: shape.position.x,
                                                                      y: shape.position.y,
                                                                  }}
                                                                  minWidth={10}
                                                                  minHeight={10}
                                                                  bounds="parent"
                                                                //   onTouchStart={()=>btnClicked==="tableChange"?tableShiftClicked(shape): handleShapeSelected(shape)}
                                                                  onClick={()=>btnClicked==="tableChange"?tableShiftClicked(shape): handleShapeSelected(shape)}
                                                                  enableResizing={false}
                                                                  disableDragging={true}
                                                              >
                                                                  {
                                                                    
                                                                      shape.type === shapeTypes.SHAPE ? (
                                                                          <div
                                                                              className="shape-container "
                                                                              style={{ border: "transparent" }}
                                                                          >
                                                                              {shape?.structure === structures.LINESEGMENTTOOL ? (
                                                                                  <LineSegmentTool
                                                                                      color={
                                                                                          // selectedElementDetails?._id === shape._id
                                                                                          // ? "#e91d1d"
                                                                                          // :
                                                                                          shape?.color
                                                                                      }
                                                                                      width={shape?.size?.width}
                                                                                      height={shape?.size?.height}
                                                                                  />
                                                                              ) : shape?.structure === structures.SPLITFLOOR ? (
                                                                                  <div
                                                                                      className="shape-container"
                                                                                      style={{
                                                                                          backgroundColor:
                                                                                              // selectedElementDetails?._id === shape._id
                                                                                              //   ? "#e91d1d"
                                                                                              //   : 
                                                                                              shape?.color,
                                                                                      }}
                                                                                  />
                                                                              ): shape?.structure === structures.HORIZONTAL ? (
                                                                                <div
                                                                                  className="shape-container"
                                                                                  style={{
                                                                                    backgroundColor:shape?.color,
                                                                                  }}
                                                                                />
                                                                              ) : shape?.structure === structures.STAR ? (
                                                                                  <StarTool
                                                                                      color={
                                                                                          // selectedElementDetails?._id === shape._id
                                                                                          //   ? "#e91d1d"
                                                                                          //   :
                                                                                          shape?.color
                                                                                      }
                                                                                      width={shape?.size?.width}
                                                                                      height={shape?.size?.height}
                                                                                  />
                                                                              ) : (
                                                                                  ""
                                                                              )}
                                                                          </div>
                                                                      ) : shape.type === shapeTypes.TABLE ? (
                                                                            <>
                                                                          {btnClicked==="tableChange"?
                                                                            <div
                                                                            //   onClick={tableShiftClicked(shape)}
                                                                              className={`shape-container ${shape.structure}`}
                                                                              style={{
                                                                                backgroundColor:
                                                                                ((!(getFirstObject(shape?.orders)===orderIdForPos || !(getFirstObject(shape?.orders) &&  !(branchConfigurationList?.enableMultipleOrder)))))?(
                                                                                    orderIdColorMapping[getFirstObject(shape?.orders)] || (setOrderIdColorMapping((prevMapping) => ({ ...prevMapping, [getFirstObject(shape?.orders)]: getRandomColor() })), orderIdColorMapping[getFirstObject(shape?.orders)])
                                                                                ):
                                                                                 (selectedTables.includes(shape._id) ? 'red' : '#80808082'),
                                                                                cursor: "pointer"
                                                                              }}
                                                                              >
                                                                              <span>{shape.name}</span>
                                                                          </div>:
                                                                            <div
                                                                              className={`shape-container ${shape.structure}`}
                                                                              style={{
                                                                                backgroundColor:
                                                                                (getFirstObject(shape?.orders) &&  !(branchConfigurationList?.enableMultipleOrder))?(
                                                                                    orderIdColorMapping[getFirstObject(shape?.orders)] || (setOrderIdColorMapping((prevMapping) => ({ ...prevMapping, [getFirstObject(shape?.orders)]: getRandomColor() })), orderIdColorMapping[getFirstObject(shape?.orders)])
                                                                                ):
                                                                                 (selectedTables.includes(shape._id) ? 'red' : '#80808082'),
                                                                                cursor: "pointer"
                                                                              }}
                                                                              >
                                                                              <span>{shape.name}</span>
                                                                          </div>
                                                                          
                                                                          }
                                                                              </>
                                                                      ) : (
                                                                          <div
                                                                              className="chair-container"
                                                                              // onClick={() => handleShapeSelected(shape)}
                                                                              style={{ cursor: "pointer" }}
                                                                          >
                                                                              <Chair
                                                                                  color={
                                                                                    btnClicked==="tableChange"?
                                                                                    ((!(getFirstObject(shape?.orders)===orderIdForPos || !(getFirstObject(shape?.orders) &&  !(branchConfigurationList?.enableMultipleOrder))))?(
                                                                                        orderIdColorMapping[getFirstObject(shape?.orders)] || (setOrderIdColorMapping((prevMapping) => ({ ...prevMapping, [getFirstObject(shape?.orders)]: getRandomColor() })), orderIdColorMapping[getFirstObject(shape?.orders)])
                                                                                    ):
                                                                                     (selectedChairs.includes(shape._id) ? 'red' : '#80808082')):
                                                                                    ((getFirstObject(shape?.orders) &&  !(branchConfigurationList?.enableMultipleOrder))?(
                                                                                        orderIdColorMapping[getFirstObject(shape?.orders)] || (setOrderIdColorMapping((prevMapping) => ({ ...prevMapping, [getFirstObject(shape?.orders)]: getRandomColor() })), orderIdColorMapping[getFirstObject(shape?.orders)])
                                                                                    ):
                                                                                     (selectedChairs.includes(shape._id) ? 'red' : '#80808082'))
                                                                                  }
                                                                                  width={shape?.size?.width}
                                                                                  name={shape?.name}
                                                                                  height={shape?.size?.height}
                                                                              />
                                                                          </div>
                                                                      )
                                                                  }
                                                              </Rnd>
                                                          ))}
                                                      </div>
                                                  </div>}
                                      </>
                                  }
                              </>}
                  </Box>

                  {
                (selectedChairs.length!==0 || selectedTables.length!==0 || orderIdForPos!==null) &&    
                <div className="button-container-floorPlan">
                    {
                        btnClicked==="tableChange"?
                        <button 
                    className="btn btn-primary"
                     onClick={updateTableSelected}>{"Transfer Table"}</button>
                        :<button 
                    className="btn btn-primary"
                     onClick={orderIdForPos===null? addOrderFloorPlan :updateOrderFloorPlan}>{orderIdForPos===null?"Add Order":"Update Order"}</button>
                  }

                {tableOrder?.length!==0 && branchConfigurationList?.enableMultipleOrder &&
                  <div className="multi-order-update-ctn">
                  {    
                    tableOrder?.filter((item, index, self) => 
                        index === self.findIndex((t) => t._id === item._id)
                      )?.map((item,i)=>(
                        <div className="order-ctn justify-space-between">
                            <p>{item?.orderName}</p>
                            <button 
                             className="btn btn-primary"
                             onClick={()=>updateMultiOrder(item?._id)}>
                            <i className="bi bi-pencil-square edit-icon"></i>
                            </button>
                        </div>
                    ))
                  }
                  </div>}
                </div>
                  }
              </div>
          </div>
      </div>
  )
}

export default BillingFloorPlan